import axios from 'axios';
var CryptoJS = require("crypto-js");


import { Clipboard } from '@capacitor/clipboard';

const writeToClipboard = async (p) => {
  await Clipboard.write({
    string: p,
    label: "data"
  });
};

export default {
    data: function() {
        return {
            pList : [],
            cards: ['Passwords'],
            dialog: false,
            dialog2: true,
            valid: true,
            key: "",
            edit: false,
            search: "",
            digits: true,
            symbols: true,
            letters: true,
            hostname: "",
            password: "",
            remark: "",
            pLen: 32,
            import: undefined,
            lens: [8, 16, 32, 64].reverse(),
            file: undefined,
        };
    },
    computed: {
        filteredItems() {
            return this.pList.filter(item => {
              if (!this.search) {
                 return this.pList;
              } else {
                 return item.host.toLowerCase().includes(this.search.toLowerCase());
              }
              
            });
        }
    },
    mounted: function () {
      window.setInterval(() => {
        this.updateList()
      }, 30000)
    },
    name: "Me",
    methods: {
        clearSearch () {
          this.search="";
        },
        validate () {
            console.log(this.$refs.form[0].validate());
        },
        setupKey () {
            this.dialog2 = false;
            var key = document.getElementById('key').value;
            this.key = key;
            if (this.edit) {
                // push
                this.upload()
                    .then(
                        response => {
                            if (response.status != 200) {
                                alert("Could not upload new password");
                            }
                        }
                    );
            }
            this.updateList()
            this.edit = false;
        },
        upload () {
            var ciphertext = this.encrypt(this.pList)
            var data = "vault=" + ciphertext
            return axios.post('https://rempass-rs.matarch.fr/vault', data, {withCredentials: true})
        },
        doExport () {
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.encrypt(this.pList)));
          element.setAttribute('download', 'export.bin');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        },
        doImport () {
          if (this.file) {
            const reader = new FileReader()
            reader.onload = (e) => {
              var clear = this.decrypt(e.target.result)
              this.pList = clear
              this.file = undefined
              this.upload()
            }
            reader.readAsText(this.file)
          }
        },
        doAddPassword(){
            this.dialog=false;
            var hostname = this.hostname;
            var password = this.password;
            var remark = this.remark;
            
            this.pList.push({"host": hostname, "password": password, "remark": remark})
            this.upload()
                .then(
                    response => {
                        console.log(response);
                        this.updateList();
                    }
                ).catch( function (error) {
                    console.log(error)
                }
            );
            this.hostname = ""
            this.password = ""
            this.remark = ""
        },
        generatePassword() {
            var charset = ""
            if (this.letters) {
              charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
            }
            if (this.digits) {
              charset += '0123456789'
            }
            if (this.symbols) {
              charset += '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
            }

            if (charset != "") {
              var out = ""
              for (var i=0; i<this.pLen; i++) {
                out += charset[Math.floor(charset.length * Math.random())];
              }
              this.password = out
            } else {
              this.password = ""
            } 

        },
        doDeletePassword(k){
            var pList = []
            for (var i in this.pList){
                var obj = this.pList[i];
                if (obj["host"] != k) {
                    pList.push(obj);
                }
            }
            this.pList = pList;

            var ciphertext = this.encrypt(this.pList);
            var data = "vault=" + ciphertext
            axios.post('https://rempass-rs.matarch.fr/vault', data, {withCredentials: true})
                .then(
                    response => {
                        console.log(response);
                        this.updateList();
                    }
                ).catch( function (error) {
                    console.log(error)
                }
            );

        },
        doCopyValue(p) {
          writeToClipboard(p)

        },
        hex_to_ascii(str1) {
            var hex  = str1.toString();
            var str = '';
            for (var n = 0; n < hex.length; n += 2) {
                str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
            }
            return str;
        },
        encrypt(d) {
            var data = JSON.stringify(d)
            var data2 = CryptoJS.AES.encrypt(data, this.key)
            var data3 = btoa(data2.toString())
            return data3
        },
        decrypt(d) {
            var data = atob(d)
            var data2 = CryptoJS.AES.decrypt(data, this.key)
            var data3 = this.hex_to_ascii(data2.toString())
            return JSON.parse(data3)
        },
        updateList() {
            axios.get('https://rempass-rs.matarch.fr/vault', {withCredentials: true})
                .then(
                    response => {
                        if (response.status != 200) {
                            window.location.href = "/login"
                        } else {
                            if (Object.keys(response.data).length == 0) {
                                console.log("new account!")
                            } else {
                                if (Array.isArray(response.data)) {
                                    this.pList = response.data;
                                } else { 
                                    try {
                                        var pList = this.decrypt(response.data);
                                        for (var p of pList) {
                                          if (!p.remark) {
                                            p.remark = ""
                                          }
                                        }
                                        this.pList = pList;
                                    } catch(error) {
                                        console.log(error);
                                        this.dialog2=true;
                                    }

                                }
                            }
                        }
                    }
                ).catch( function (error) {
                  console.log(error)
                  window.location.href = '/login';
                })
        },
    },
    watch: {
        key: function () {
            if (!self.edit) { this.updateList(); }
        },
    },
}
