<template>
  <v-form ref="form" @submit.prevent="Login">
    <div class="text-center">
      <v-btn
        :loading="loading"
        color="success"
        large
        type="submit"
        rounded
        >Login via SSO</v-btn
      >
    </div>
  </v-form>
</template>

<script>
/*
In general, the flow should be:

    open a pop-up with an authorization page (on twitter.com for example)
    after successfull authorization twitter redirects user to url given by you (it gets opened in the very same pop-up)
    the opener window gets reloaded (via window.opener.location.reload())
    close the pop-up itself (using javascript is you want)

*/
import axios from 'axios';

export default {
  name: "Login",
  props: {
    msg: String
  },

  data: function () {
    return {
      error: undefined,
      loading: false,
    }
  },
  mounted: function () {
    if (this.check_auth()) {
      window.location = "/me";
    }
  },
  methods: {
    Login() {
      window.open('https://rempass-rs.matarch.fr/', 'rempass_auth', 'menubar=no,toolbar=no,location=no,status=no,personalbar=no,directories=no,width=400,height=600');
    },
    check_auth() {
      axios.get('https://rempass-rs.matarch.fr/vault', {withCredentials: true})
        .then(
          response => {
            if (response.status != 200) {
              return false;
            }
            return true;
          }
      )
    }
  }
}
</script>
