<template>
<div></div>
</template>


<script>
export default {
    name: "Landing",
    mounted() {
        let rawparams = window.location.search;
        let params = new URLSearchParams(rawparams);
        let psession = params.get("session");
        sessionStorage.setItem('SESSION', psession);
        let w = window.opener;
        w.location.href = "/me";

        window.close();
    }
}
</script>
