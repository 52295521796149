import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from "@/layouts/Auth"
import MeLayout from "@/layouts/Me"
import LandingLayout from "@/layouts/Landing"
import Login from '../views/Login.vue'
import Landing from '../views/Landing.vue'
import Me from '../views/Me.vue'
import Redirector from '../views/Redirector.vue'
import RedirectorLayout from "@/layouts/Redirector"

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        component: AuthLayout,
        children: [
              {
                path: "/login",
                name: "login",
                component: Login,
                meta: {
                      title: "Login",
                },
              },
          ],
    },
    {
        path: "/",
        component: RedirectorLayout,
        children: [
              {
                path: "/",
                name: "redirector",
                component: Redirector,
                meta: {
                      title: "redirector",
                },
              },
          ],
    },
    {
        path: "/me",
        component: MeLayout,
        children: [
              {
                path: "/me",
                name: "me",
                component: Me,
                meta: {
                      title: "Me",
                },
              },
          ],
    },
    {
        path: "/landing",
        component: LandingLayout,
        children: [
              {
                path: "/landing",
                name: "landing",
                component: Landing,
                meta: {
                      title: "Landing",
                },
              },
        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    base: "https://rempass-web.matarch.fr/",
    routes
})

export default router
