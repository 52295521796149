<template>
<div></div>
</template>

<script>
export default {
    mounted() {
        window.location.href = "/login"
    }
}
</script>
