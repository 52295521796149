var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{staticClass:"py-8 px-6",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center","dense":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-text-field',{staticClass:"mx-3",attrs:{"flat":"","label":"Search","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"align-center":""},on:{"click":function($event){return _vm.doExport()}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Export")],1)],1),_c('v-col',[_c('v-file-input',{attrs:{"accept":"*","label":"Import"},on:{"change":function($event){return _vm.doImport()}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_vm._l((_vm.cards),function(card){return _c('v-col',{key:card,attrs:{"cols":"12"}},[_c('v-card',[_c('v-subheader',[_vm._v(_vm._s(card)+" "),_c('v-col',{staticClass:"text-right"},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Nouveau mot de passe")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",refInFor:true,attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.test.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"hostname","id":"hostname","required":""},model:{value:(_vm.hostname),callback:function ($$v) {_vm.hostname=$$v},expression:"hostname"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"password","placeholder":"password","id":"password","type":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"remarque","placeholder":"remarque","id":"remarque"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":"Lettres"},model:{value:(_vm.letters),callback:function ($$v) {_vm.letters=$$v},expression:"letters"}}),_c('v-checkbox',{attrs:{"label":"Nombres"},model:{value:(_vm.digits),callback:function ($$v) {_vm.digits=$$v},expression:"digits"}}),_c('v-checkbox',{attrs:{"label":"Symboles"},model:{value:(_vm.symbols),callback:function ($$v) {_vm.symbols=$$v},expression:"symbols"}})],1),_c('v-row',[_c('v-menu',{attrs:{"top":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v("Taille")])]}}],null,true)},[_c('v-list',_vm._l((_vm.lens),function(len){return _c('v-list-item',{key:len,on:{"click":function($event){_vm.pLen = len}}},[_c('v-list-item-title',[_vm._v(_vm._s(len))])],1)}),1)],1),_c('v-btn',{on:{"click":function($event){return _vm.generatePassword()}}},[_vm._v("Generer un mdp aléatoire")])],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue en-1","type":"submit","text":""},on:{"click":function($event){_vm.dialog = false; _vm.hostname = ''; _vm.password=''; _vm.hostname=''}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue en-1","text":""},on:{"click":function($event){return _vm.doAddPassword()}}},[_vm._v(" Save ")])],1)],1)],1),_c('v-btn',{on:{"click":_vm.updateList}},[_c('v-icon',[_vm._v("mdi-cached")])],1),_c('v-btn',{on:{"click":function($event){_vm.dialog2 = true; _vm.edit = true}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.filteredItems),function(row){return _c('v-list-item',{key:row.host},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-menu',{attrs:{"offset-x":"","nudge-width":200,"open-on-hover":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(row.host)),(row.remark != '')?_c('v-icon',[_vm._v("mdi-information")]):_vm._e()],1)]}}],null,true)},[_c('v-card',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(row.host)+" ")]),_c('v-divider'),_c('v-list-item-subtitle',[_vm._v(_vm._s(row.remark ? row.remark : "Pas de remarques")+" ")])],1)],1)],1)],1),_c('v-list-item-action')],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{on:{"click":function($event){return _vm.doCopyValue(row.password)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{on:{"click":function($event){return _vm.doDeletePassword(row.host)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),1)],1)],1)})],2)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Entrez le mot de passe de chiffrement ")]),_c('v-container',[_c('v-text-field',{attrs:{"label":"key","id":"key","type":"password","required":""}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.setupKey}},[_vm._v(" Validate ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }