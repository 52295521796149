<template>
  <v-app id="inspire">

    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-sheet
        class="pa-4"
      >

        <div>rempass-rs<br>Password Manager</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="(item, index) in groups"
          :key="index"
          @click="menuActionClick(item.action)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-sheet>
      <transition name="fade" mode="out-in">
          <router-view></router-view>
      </transition>
    </v-sheet>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: null,
      groups: [
        {icon: 'mdi-lock', text: 'Password Manager', action: 'None'}, 
        {icon: 'mdi-cloud', text: 'Cloud', action: 'new_window_to_cloud'}, 
        {icon: 'mdi-chat', text: 'Element', action: 'new_window_to_element'},
      ],
    }),
   methods: {
       menuActionClick(action) {
           console.log(action)
           if (action == 'new_window_to_element') {
             window.open("https://web.matarch.fr", '_blank')
           } else if (action == 'new_window_to_cloud') {
             window.open("https://cloud.matarch.fr", '_blank')
           }
       }
   },
  }
</script>
